import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { InputAdornment, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PurpleCheckmark } from 'assets/icons/purpleCheckmark.svg';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { useState } from 'react';
import assert from 'shared/utils/assert';
import BaseInput from './BaseInput';

export interface Option {
  name: string;
  value: any;
  disabled?: boolean;
}

/**
 * Non-component function which returns a single MenuItem (select option).
 *
 * NOTE: Of course, this *should* be an Option component ... but MUI does some
 *       weird stuff to the children of a Select, so we have to make this
 *       "component factory function" instead of a (more sane) component.
 * @see https://stackoverflow.com/questions/68378474/mui-select-custom-menuitem-not-working-properly
 */
const renderOption = ({ disabled = false, name, value }: Option) => (
  <MenuItem
    className={
      `m-md !font-mono ` +
      // Style the selected options differently from un-selected ones
      `[&.Mui-selected]:!bg-gray500 ` +
      // hover: white on slightly lighter gray
      `hover:!bg-gray700 ` +
      `!overflow-visible !whitespace-normal ` +
      `[&.Mui-focusVisible]:!bg-gray600 ` +
      // disabled: dark gray on even darker gray
      (disabled ? `!text-gray600 !opacity-100 ` : ``)
    }
    key={value}
    {...{ disabled, value }}
  >
    <div className={`!flex !whitespace-nowrap p-[3px] text-gray50 `}>
      <div
        className={
          `mr-sm max-w-[200px] ` +
          `!block overflow-hidden text-ellipsis whitespace-nowrap `
        }
      >
        {name}
      </div>
    </div>
    <PurpleCheckmark className={' ml-xxxl hidden stroke-purple200'} />
  </MenuItem>
);

/**
 * Renders a single value that the user has selected, inside the select's input.
 */
const SelectedValue = ({
  name,
  number,
  removeValue,
  value
}: {
  name: string;
  number?: number;
  removeValue?: any;
  value?: string | Option;
}) => {
  const [isXHovered, setIsXHovered] = useState(false);
  return (
    <div
      className={
        `mx-[1px] my-[2px] !font-mono ` +
        ` text-gray50 ` +
        `!block !overflow-visible !whitespace-normal ` +
        `!flex items-center !whitespace-nowrap `
      }
    >
      <div
        className={
          `mb-[1px] ml-[4px] mr-[8px] max-w-[170px] rounded-sm !bg-gray700 ` +
          `overflow-hidden text-ellipsis whitespace-nowrap px-[4px] py-[3px] `
        }
      >
        {name}
      </div>

      {Number(number) > 0 && (
        <div
          className={
            `mb-[1px] ml-[4px] mr-[8px] max-w-[170px] rounded !bg-gray700 ` +
            `overflow-hidden text-ellipsis whitespace-nowrap px-[4px] py-[3px] `
          }
        >
          +{number}
        </div>
      )}

      {removeValue && (
        <CloseIcon
          className={
            'mr-[4px] mt-[4px] h-[16px] w-[16px] rounded p-[3px] ' +
            (isXHovered ? 'bg-gray500 fill-white ' : 'fill-gray300 ')
          }
          onClick={e => {
            e.stopPropagation();
            removeValue(value ?? name);
          }}
          onMouseEnter={() => setIsXHovered(true)}
          onMouseLeave={() => setIsXHovered(false)}
        />
      )}
    </div>
  );
};

const CondensedValues = ({ getOptionName, values }) => {
  if (!values.length) return '';
  return (
    <SelectedValue name={getOptionName(values[0])} number={values.length - 1} />
  );
};

const BaseSelect = ({
  allowsMultiple = false,
  allValuesAreShown = false,
  className = '',
  multipleValuesInOneRow = false,
  onChange,
  options,
  shouldCondenseValues = false,
  size = '',
  startAdornment = null,
  value,
  width,
  ...muiProps
}: {
  allowsMultiple?: boolean;
  allValuesAreShown?: boolean;
  className?: string;
  multipleValuesInOneRow?: boolean;
  onChange: any;
  options: (string | Option)[];
  shouldCondenseValues?: boolean;
  size?: 'small' | '';
  startAdornment?: any;
  value: any;
  width?: number | '100%';
  [key: string]: any; // Passed-through MUI TextFIild props
}) => {
  assert(options, 'All selects must have a list of options');
  assert(onChange, 'All selects must have an onChange handler');

  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const openMenu = () => setIsOpen(true);

  const defaultValue = allowsMultiple ? [] : '';

  // If any of the options are just strings, convert them to name/value objects
  const parsedOptions = options.map(option =>
    isString(option) ? { name: option, value: option } : option
  );
  muiProps.value ||= defaultValue;
  assert(
    !allowsMultiple || isArray(muiProps.value),
    `Multi-selection selects should have an array as their value`
  );
  const removeValue =
    allowsMultiple &&
    (toRemove =>
      onChange({ target: { value: value.filter(v => v !== toRemove) } }));

  /**Finds an option's name using it's value */
  const getOptionName = value => {
    if (isObject(value)) return (value as Option).name;

    const matchingOption = options.find(
      option => (isObject(option) ? option?.value : option) === value
    );
    return isObject(matchingOption) ? matchingOption.name : matchingOption;
  };

  const isFullWidth = width === '100%';
  return (
    <div
      className={
        `flex items-center justify-center ` +
        (value?.length ? 'pr-[30px] ' : '') + // make space for reset button
        (isFullWidth ? '!min-w-full' : 'max-w-[250px]') +
        ' ' +
        className
      }
    >
      <BaseInput
        // Classes for the field('s wrapping div)
        className={
          `flex bg-transparent ` +
          (isFullWidth ? `` : '!min-w-[25ch] ') +
          // (allValuesAreShown ? '[&_.MuiSelect-select]:flex-col ' : '') +
          // The select's "input" text (where the slected values go)
          `![&_.MuiOutlinedInput-root]:text-gray50 ` +
          /**
           * The "select" (ie. the component that wraps the options)
           *
           * NOTE: We're defining these styles here so they *only* apply to the
           *       option when it's in the menu, not when it's a selected value)
           *
           * Make the "select" a flexbox, and add a little left margin to add
           * space after each comma (don't add that margin to the first item, as
           * it doesn't have a comma before it).
           */
          `[&_.MuiSelect-select]:flex ` +
          `[&_.MuiSelect-select]:flex-wrap ` +
          `[&_.MuiSelect-select]:!bg-transparent ` +
          `[&_.MuiSelect-select>div]:[&:nth-child(0)]:ml-0 ` +
          `[&_.MuiSelect-select>div]:ml-sm ` +
          '[&_.MuiSelect-select]:flex-col ' +
          // But show the legend when the select is focused
          `[&_.Mui-focused_legend]:block `
        }
        onClick={e => {
          // Don't open the menu when clicking the "X" to remove a value
          if (e.target.tagName === 'svg') return closeMenu();
          /// But in all other cases, toggle the menu with each click
          isOpen ? closeMenu() : openMenu();
        }}
        SelectProps={{
          displayEmpty: true,
          endAdornment: !!value?.length && (
            <InputAdornment className="mr-[-40px] " position="end">
              {/* TODO: Use the tooltip form the menu branch (in shared, but not the GC
                  one, instead of the base MUI one  */}
              <Tooltip placement="top" title="Reset">
                <RestartAltIcon
                  className="cursor-pointer !text-gray300"
                  onClick={e => {
                    e.stopPropagation();
                    // TS is so pointless ...
                    const target = e.currentTarget as SVGElement;
                    target.classList.add('animate-spinReverse');
                    setTimeout(
                      () => target.classList.remove('animate-spinReverse'),
                      250
                    );
                    onChange({ target: { value: defaultValue } });
                  }}
                />
              </Tooltip>
            </InputAdornment>
          ),
          multiple: allowsMultiple,
          MenuProps: {
            classes: {
              // Classes for the options dropdown menu (ie. <ul>)
              list:
                'text-white ' +
                `[&_.Mui-selected_svg]:block ` +
                // You'd think we'd want to put all the option styles below
                // under MenuItem ... but as it turns out, MUI uses those same
                // MenuItems to render the selected options, inside the "input"
                // That means the menu styles can break the value rendering :(
                //
                // Solution: Define "menu-only" styles here with Tailwind

                `[&_.MuiMenuItem-root_div]:flex ` +
                `[&_.MuiMenuItem-root_div]:justify-between ` +
                `[&_.MuiMenuItem-root_div]:items-center ` +
                `[&_.MuiMenuItem-root_div]:w-full ` +
                `[&_.MuiMenuItem-root_div]:!max-w-[calc(100%_-_20px)] `

              // `[&_.MuiMenuItem-root_svg]:block `
            },
            PaperProps: {
              className: '!bg-gray800'
            },
            style: { width }
          },
          onChange,
          onClose: closeMenu,
          open: isOpen,
          // NOTE: When this gets merged with the refactored code that has this
          //       in a separate file, the important part to keep from here is
          //       just the multipleValuesInOneRow logic (the rest can be thrown
          //       out in favor of the refactored version)
          // NOTE #2: And the condendesed values logic
          renderValue: (selected: string | Option | string[] | Option[]) => {
            if (!selected) return '';
            if (allowsMultiple) {
              assert(
                isArray(selected),
                `Multiple-value selects must have an array of values ` +
                  `(but was "${selected}")`
              );
              return shouldCondenseValues ? (
                <CondensedValues {...{ getOptionName }} values={value} />
              ) : (
                (selected as string[] | Option[]).map(value => (
                  <SelectedValue
                    key={value}
                    name={getOptionName(value)}
                    {...{ removeValue, value }}
                  />
                ))
              );
            } else {
              assert(
                isString(selected) || isObject(selected),
                `Single-value selects must have a singular value ` +
                  `(but was "${selected}")`
              );
              const value = (selected as Option)?.value ?? selected;
              const name = getOptionName(value);
              return (
                <SelectedValue key={value} {...{ name, removeValue, value }} />
              );
            }
          },
          startAdornment
        }}
        select
        {...{ ...muiProps, size, value, width }}
      >
        {parsedOptions.map(renderOption)}
      </BaseInput>
    </div>
  );
};

export default BaseSelect;
