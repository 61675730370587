import { gql, useQuery } from '@apollo/client';
import { COUNTRIES } from 'App/Settings/ThreatProfiles/EditProfile/mappings';
import sortBy from 'lodash/sortBy';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState
} from 'react';
import { buildUseContext } from 'shared/utils/contextUtil';

export const GlobalFiltersContext = createContext<{
  allCountries: { name: string; value: string }[];
  allIndustries: { name: string; value: string }[];
  setFilterCountries: Dispatch<SetStateAction<string[]>>;
  setFilterIndustries: Dispatch<SetStateAction<string[]>>;
  variables: {
    countries: string[];
    industries: string[];
  };
}>({
  // All available countries (as label/value objects)
  allCountries: [],
  // All available industries (as label/value objects)
  allIndustries: [],
  setFilterCountries: () => {},
  setFilterIndustries: () => {},
  variables: {
    countries: [],
    industries: []
  }
});

/**
 * This helper hook fetches the industries from the server, parses them into
 * option objects (once they come back), and then returns them.
 */
const useAllIndustries = () => {
  const [allIndustries, setAllIndustries] = useState([]);
  const { data } = useQuery(gql`
    query GlobalIndustries {
      industries {
        label
        value
      }
    }
  `);
  useEffect(() => {
    if (!data) return;

    const industriesWithLabels = data.industries.filter(i => i.label);
    const sortedIndustries = sortBy(industriesWithLabels, 'label');
    const industries = sortedIndustries.map(
      ({ label, value }) => ({ name: label, value }),
      {}
    );
    setAllIndustries(industries);
  }, [data]);
  return allIndustries;
};

export const GlobalFiltersProvider = ({
  children
}: PropsWithChildren): ReturnType<typeof GlobalFiltersContext.Provider> => {
  // All possible values (to make the options for the dropdowns)
  const allCountries = Object.entries(COUNTRIES).map(([value, label]) => ({
    name: label,
    value
  }));
  const allIndustries = useAllIndustries();

  // The current filter selections (and setters to change them)
  const [countries, setFilterCountries] = useState([]);
  const [industries, setFilterIndustries] = useState([]);

  return (
    <GlobalFiltersContext.Provider
      value={{
        allCountries,
        allIndustries,
        setFilterCountries,
        setFilterIndustries,
        // Since these values are going to be used in a query's "variables"
        // object, it makes sense (for the consumers) to get them as a single
        // object
        variables: { countries, industries }
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export const useGlobalFiltersContext = buildUseContext(
  GlobalFiltersContext,
  `GlobalSearchContext`
);
