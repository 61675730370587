import { Button, DotsLoader, useNotification } from 'reablocks';
import { useState } from 'react';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import css from 'shared/utils/ExportButton/ExportButton.module.css';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';

const AttackNavigatorButton = ({ techniques, selectedCoverage }) => {
  const { notifyError } = useNotification();

  const transformToNavigatorFormat = techniques => {
    // Will need to update this over time
    const navigatorJson = {
      name: startCase(selectedCoverage),
      versions: {
        attack: '16',
        navigator: '5.1.0',
        layer: '4.5'
      },
      domain: 'enterprise-attack',
      description: `Enterprise ATT&CK showing colored by ${startCase(selectedCoverage)}.`,
      filters: {
        platforms: [
          'Windows',
          'Linux',
          'macOS',
          'Network',
          'PRE',
          'Containers',
          'IaaS',
          'SaaS',
          'Office Suite',
          'Identity Provider'
        ]
      },
      sorting: 0,
      layout: {
        layout: 'side',
        aggregateFunction: 'average',
        showID: false,
        showName: true,
        showAggregateScores: false,
        countUnscored: false,
        expandedSubtechniques: 'none'
      },
      hideDisabled: false,
      techniques: [],
      gradient: {
        colors: ['#ff6666ff', '#ffe766ff', '#8ec843ff'],
        minValue: 0,
        maxValue: 100
      },
      legendItems: [
        {
          label: 'Full Coverage',
          color: '#8ec843ff'
        },
        {
          label: 'Moderate Coverage',
          color: '#ffe766ff'
        },
        {
          label: 'Poor Coverage',
          color: '#ff6666ff'
        }
      ],
      metadata: [],
      links: [],
      showTacticRowBackground: false,
      tacticRowBackground: '#dddddd',
      selectTechniquesAcrossTactics: true,
      selectSubtechniquesWithParent: false,
      selectVisibleTechniques: false
    };

    const transformedTechniques = techniques.flatMap(technique => {
      return technique.tacticNames.map(tactic => ({
        techniqueID: technique.mitreId,
        tactic: tactic.toLowerCase().replace(/\s+/g, '-'),
        score: technique[selectedCoverage],
        color: '',
        comment: `Score Type: ${startCase(selectedCoverage)}, Priority: ${technique.priority}, Content: ${technique.content}, Detection: ${technique.detectionsPercentage}, Telemetry: ${technique.telemetryPercentage}`,
        enabled: true,
        metadata: [],
        links: [],
        showSubtechniques: false
      }));
    });

    navigatorJson.techniques = transformedTechniques;
    return navigatorJson;
  };

  const [loading, setLoading] = useState(false);

  const downloadJson = async () => {
    try {
      setLoading(true);

      const jsonData = await new Promise(resolve => {
        setTimeout(() => {
          resolve(transformToNavigatorFormat(techniques));
        }, 0);
      });

      const jsonString = JSON.stringify(jsonData, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `navigator_${snakeCase(selectedCoverage)}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notifyError(`Failed to export data`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      type="button"
      variant="outline"
      disabled={loading}
      onClick={downloadJson}
      startAdornment={<ExportIcon className={css.exportIcon} />}
    >
      {loading ? <DotsLoader /> : 'Export'}
    </Button>
  );
};

export default AttackNavigatorButton;
