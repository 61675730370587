import Button from '@mui/material/Button';
import { MouseEventHandler, PropsWithChildren } from 'react';

type MuiPaletteType =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';

const BaseButton = ({
  children,
  className = '',
  color = 'primary',
  disabled = false,
  disabledClassName = 'disabled:!bg-gray500 disabled:!text-gray300',
  onClick,
  ...props
}: PropsWithChildren<{
  color?: MuiPaletteType;
  // NOTE: onClick is optional because while *most* buttons will have one, but
  //       some will just trigger their form's onSubmit
  onClick?: MouseEventHandler<HTMLButtonElement>;
  [key: string]: any; // Passed-through MUI Button props
}>) => {
  className += ' ' + disabledClassName;
  return (
    <Button {...{ ...props, className, disabled, onClick }} variant="contained">
      {children}
    </Button>
  );
};

export default BaseButton;
