import TextField from '@mui/material/TextField';
import { PropsWithChildren } from 'react';

const BaseInput = ({
  children = null,
  className = '',
  errorMessage,
  helperText = '',
  isDisabled,
  width = '100%',
  ...muiProps
}: PropsWithChildren<{
  className?: string;
  errorMessage?: string;
  helperText?: string;
  width?: number | '100%';
  [key: string]: any; // Passed-through MUI TextFIild props
}>) => (
  <TextField
    {...muiProps}
    className={
      // NOTE: Tailwind didnt have this property (when I wrote this at least)
      //       (it's needed to override a MUI color)
      `[-webkit-text-fill-color:initial] ` +
      `!visible ` +
      `[&_.MuiSelect-icon]:!fill-gray50 ` +
      /**
       * Input
       * - Default: lighter gray
       * - Disabled: darker gray
       */
      `[&_input]:has-[_.Mui-disabled]:!text-gray300 ` +
      `[&_input]:!text-gray-50 ` +
      /**
       * Placeholder
       * - Default: off-white
       */
      `[&_input]:placeholder:!text-gray50 [&_textarea]:!text-gray50 ` +
      /**
       * Label
       * - our main font
       * - Default: gray
       * - Active/Focused: purple
       * - Disabled: dark gray
       * - Error: red
       */
      `[&_label]:font-main ` +
      `[&_label]:text-gray50 ` +
      `[&_label]:has-[_.Mui-focused]:!text-purple200 ` +
      // "shrink" means the label has gone to the top part of the box
      // (it starts inside the box); when it's up top it needs a background
      `[&_label.MuiInputLabel-shrink]:!bg-gray600 ` +
      `[&_label]:has-[_.Mui-disabled]:!text-gray500 ` +
      `[&_label]:has-[_.Mui-error]:!text-error ` +
      /**
       * Helper Text:
       * - our main font
       * - Normal: off-white
       * - Error: red
       */
      `[&_.MuiFormHelperText-root]:font-main ` +
      `[&_.MuiFormHelperText-root]:!text-gray50 ` +
      `[&_.MuiFormHelperText-root]:has-[_.Mui-error]:!text-error ` +
      /**
       * Borders
       * Default: gray
       * Hover: off-white
       * Active/Focused: purple (2px wide)
       * Disabled: dark gray
       * Error: red
       */
      `[&_fieldset]:!border [&_fieldset]:!border-solid [&_fieldset]:!border-slate800 ` +
      `[&_fieldset]:hover:!border-gray50 ` +
      `[&_fieldset]:has-[_.Mui-focused]:!border-purple200 ` +
      `[&_fieldset]:has-[_.Mui-focused]:!border-2 ` +
      `[&_fieldset]:has-[_.Mui-disabled]:!border-gray500 ` +
      `[&_fieldset]:has-[_.Mui-error]:!border-error ` +
      // provided classes
      className
    }
    disabled={!!isDisabled}
    error={!!errorMessage}
    helperText={errorMessage || helperText}
    variant="outlined"
    style={{ maxWidth: width, width }}
  >
    {children}
  </TextField>
);
export default BaseInput;
