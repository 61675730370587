import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { GlobalFiltersProvider } from 'App/GlobalFilters/GlobalFiltersContext';
import { GlobalSearchProvider } from 'App/GlobalSearch/GlobalSearchContext';
import { ApolloProvider } from 'core/Apollo';
import { Auth, AuthRoute } from 'core/Auth';
import 'core/utils/tracking';
import {
  Notifications,
  ThemeProvider as ReaBlocksThemeProvider
} from 'reablocks';
import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { UserProvider } from 'shared/user/UserContext';
import Compose from 'shared/utils/Compose/Compose';
import { ErrorBoundary } from 'shared/utils/ErrorBoundary';
import HotkeyDialog from 'shared/utils/Hotkeys/HotkeyDialog';
import { theme as reaBlocksTheme } from 'shared/utils/Theme';
import muiTheme from 'shared/utils/Theme/MuiTheme';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { App } from './App';
import './index.css';

const Login = lazy(() => import('./App/Login'));
const Welcome = lazy(() => import('./App/Welcome'));

const appId = import.meta.env.VITE_INTERCOM_APP_ID;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {/* Wrap our app with our providers, without crazy nesting/indentation */}
    <Compose
      componentsWithProps={[
        [MuiThemeProvider, { theme: muiTheme }],
        [ReaBlocksThemeProvider, { theme: reaBlocksTheme }],
        [
          IntercomProvider,
          {
            appId,
            autoBoot: false,
            autoBootProps: {
              backgroundColor: '#8100fe', // purple500
              hideDefaultLauncher: true
            },
            shouldInitialize: import.meta.env.isProd
          }
        ],
        [BrowserRouter],
        [QueryParamProvider, { adapter: ReactRouter6Adapter }],
        [ApolloProvider],
        [HelmetProvider],
        [GlobalSearchProvider]
      ]}
    >
      <Helmet
        titleTemplate="%s | Interpres"
        defaultTitle="Interpres - Baseline & prioritize your security posture"
      />
      <Notifications>
        <ErrorBoundary>
          <Auth>
            {/* This provider can't be composed because it needs to be inside Auth */}
            {/* But, there's no reason we can't compose Auth, ErroBoundary, etc.
                ... except that doing so would would wrap the Helmet element 
                inside them (with unknown consequences: presumably someone left
                it out for a reason, but maybe not?)
                TODO: Figure out if that's actually a problem, and if not,
                      compose all this stuff (if it is, delete these comments
                      and leave an explanation behind)
             */}
            <UserProvider>
              <HotkeyDialog />
              <Routes>
                <Route path="login" element={<Login />} />
                <Route element={<AuthRoute />}>
                  <Route
                    path="*"
                    element={
                      <GlobalFiltersProvider>
                        <Routes>
                          <Route path="welcome" element={<Welcome />} />
                          <Route path="*" element={<App />} />
                        </Routes>
                      </GlobalFiltersProvider>
                    }
                  />
                </Route>
              </Routes>
            </UserProvider>
          </Auth>
        </ErrorBoundary>
      </Notifications>
    </Compose>
  </React.StrictMode>
);
