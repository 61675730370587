import Popover from '@mui/material/Popover';
import { ReactComponent as CountryIcon } from 'assets/icons/country.svg';
import { ReactComponent as FilterAltIcon } from 'assets/icons/filterAlt.svg';
import { ReactComponent as IndustryIcon } from 'assets/icons/industries.svg';
import { MouseEvent, useState } from 'react';
import BaseButton from 'shared/elements/MuiBase/BaseButton';
import BaseSelect from 'shared/elements/MuiBase/BaseSelect';
import RequireRole from 'shared/user/RequireRole';
import { useGlobalFiltersContext } from './GlobalFiltersContext';

/**
 * The filter icon + "Global Filter" text, shared by both the button and popover
 */
const IconAndTitle = ({ className = '', isTall = false }) => (
  <span className={'flex flex-wrap ' + className}>
    <FilterAltIcon className="text-white" />
    <span
      className={
        'font-mono !text-purple100 ' + (isTall ? `mt-[1px]` : `mt-[-2px]`)
      }
    >
      &nbsp;Global Filter
    </span>
  </span>
);

const GlobalFiltersButton = () => {
  const {
    allCountries,
    allIndustries,
    setFilterCountries,
    setFilterIndustries,
    variables: { countries, industries }
  } = useGlobalFiltersContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // MUI's example hid the ID when the popover is closed; accessibility thing?
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'global-filters-popup' : undefined;

  // If we have any filters applied ATM we switch to a purple border
  const isApplied = countries.length || industries.length;
  const borderColorClass = isApplied ? `!border-purple500` : `!border-gray300`;

  return (
    <RequireRole role="GLOBAL_FILTER">
      <BaseButton
        className={
          'left-[50px]] !fixed bottom-lg !bg-gray600 ' +
          'z-10 rounded-sm !border !border-solid' +
          borderColorClass
        }
        data-testid="global-filters-button"
        {...{ id, onClick }}
      >
        <IconAndTitle />
      </BaseButton>
      {/* TODO: Make a BasePopover component */}
      <Popover
        {...{ anchorEl, id }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        classes={{
          paper:
            '!bg-gray600 pt-sm ' +
            'rounded-[5px] border border-solid border-purple500 '
        }}
        disablePortal
        onClose={() => setAnchorEl(null)}
        open={isOpen}
        // Position the popover so its lower-left corner aligns with the button
        transformOrigin={{ horizontal: 0, vertical: 31 }}
      >
        <div className="m-md flex flex-wrap items-center">
          <IconAndTitle className="mr-[8px]" isTall />
          <BaseSelect
            allowsMultiple
            className="mr-sm"
            label="Countries"
            multipleValuesInOneRow
            options={allCountries}
            onChange={e => {
              setFilterCountries(e.target.value);
            }}
            shouldCondenseValues
            size="small"
            startAdornment={<CountryIcon className="h-lg w-lg" />}
            value={countries}
          />
          <BaseSelect
            allowsMultiple
            label="Industries"
            multipleValuesInOneRow
            options={allIndustries}
            onChange={e => {
              setFilterIndustries(e.target.value);
            }}
            shouldCondenseValues
            size="small"
            startAdornment={<IndustryIcon className="h-lg w-lg" />}
            value={industries}
          />
        </div>
      </Popover>
    </RequireRole>
  );
};

export default GlobalFiltersButton;
