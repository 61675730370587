export const PLATFORMS = {
  IOS: 'iOS',
  IAAS: 'IaaS',
  SAAS: 'SaaS',
  CONTAINERS: 'Containers',
  ANDROID: 'Android',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  MACOS: 'macOS',
  AZURE_AD: 'Azure AD',
  CONTROL_SERVER: 'Control Server',
  DATA_HISTORIAN: 'Data Historian',
  DEVICE_CONFIGURATION_PARAMETERS: 'Device Configuration/Parameters',
  ENGINEERING_WORKSTATION: 'Engineering Workstation',
  FIELD_CONTROLLER_RTU_PLC_IED: 'Field Controller/RTU/PLC/IED',
  GOOGLE_WORKSPACE: 'Google Workspace',
  HUMAN_MACHINE_INTERFACE: 'Human-Machine Interface',
  INPUT_OUTPUT_SERVER: 'Input/Output Server',
  NETWORK: 'Network',
  OFFICE_365: 'Office 365',
  PRE: 'PRE',
  SAFETY_INSTRUMENTED_SYSTEM_PROTECTION_RELAY:
    'Safety Instrumented System/Protection Relay'
};

export const PROFILE_SIZES = {
  UNSPECIFIED: 'unspecified',
  SMALL: '1-100',
  MEDIUM: '100-1000',
  LARGE: '1000-10000',
  XLARGE: '10000+'
};

export const DATA_TYPES = {
  CPI: 'CPI',
  CUI: 'CUI',
  FERPA: 'FERPA',
  FISMA: 'FISMA',
  GLBA: 'GLBA',
  IP: 'IP',
  ITAR: 'ITAR',
  LE: 'LE',
  PCI: 'PCI',
  PHI: 'PHI',
  PII: 'PII',
  SSN: 'SSN'
};

export const INDUSTRIES = {
  AGRICULTURE_FORESTRY_FISHING_AND_HUNTING:
    'Agriculture, Forestry, Fishing and Hunting',
  MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION:
    'Mining, Quarrying, and Oil and Gas Extraction',
  UTILITIES: 'Utilities',
  CONSTRUCTION: 'Construction',
  MANUFACTURING: 'Manufacturing',
  WHOLESALE_TRADE: 'Wholesale Trade',
  RETAIL_TRADE: 'Retail Trade',
  TRANSPORTATION_AND_WAREHOUSING: 'Transportation and Warehousing',
  INFORMATION: 'Information',
  FINANCE_AND_INSURANCE: 'Finance and Insurance',
  REAL_ESTATE_AND_RENTAL_AND_LEASING: 'Real Estate and Rental and Leasing',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES:
    'Professional, Scientific, and Technical Services',
  MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES:
    'Management of Companies and Enterprises',
  ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES:
    'Administrative and Support and Waste Management and Remediation Services',
  EDUCATIONAL_SERVICES: 'Educational Services',
  HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'Health Care and Social Assistance',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, Entertainment, and Recreation',
  ACCOMMODATION_AND_FOOD_SERVICES: 'Accommodation and Food Services',
  OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION:
    'Other Services (except Public Administration)',
  PUBLIC_ADMINISTRATION: 'Public Administration'
};

export const COUNTRIES = {
  AFG: 'Afghanistan',
  ALA: '\u00c5land Islands',
  ALB: 'Albania',
  DZA: 'Algeria',
  ASM: 'American Samoa',
  AND: 'Andorra',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ATA: 'Antarctica',
  ATG: 'Antigua and Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ABW: 'Aruba',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BHS: 'Bahamas',
  BHR: 'Bahrain',
  BGD: 'Bangladesh',
  BRB: 'Barbados',
  BLR: 'Belarus',
  BEL: 'Belgium',
  BLZ: 'Belize',
  BEN: 'Benin',
  BMU: 'Bermuda',
  BTN: 'Bhutan',
  BOL: 'Bolivia (Plurinational State of)',
  BES: 'Bonaire, Sint Eustatius and Saba',
  BIH: 'Bosnia and Herzegovina',
  BWA: 'Botswana',
  BVT: 'Bouvet Island',
  BRA: 'Brazil',
  IOT: 'British Indian Ocean Territory',
  BRN: 'Brunei Darussalam',
  BGR: 'Bulgaria',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  CPV: 'Cabo Verde',
  KHM: 'Cambodia',
  CMR: 'Cameroon',
  CAN: 'Canada',
  CYM: 'Cayman Islands',
  CAF: 'Central African Republic',
  TCD: 'Chad',
  CHL: 'Chile',
  CHN: 'China',
  CXR: 'Christmas Island',
  CCK: 'Cocos (Keeling) Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  COG: 'Congo',
  COD: 'Congo (Democratic Republic of the)',
  COK: 'Cook Islands',
  CRI: 'Costa Rica',
  CIV: "C\u00f4te d'Ivoire",
  HRV: 'Croatia',
  CUB: 'Cuba',
  CUW: 'Cura\u00e7ao',
  CYP: 'Cyprus',
  CZE: 'Czech Republic',
  DNK: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  SLV: 'El Salvador',
  GNQ: 'Equatorial Guinea',
  ERI: 'Eritrea',
  EST: 'Estonia',
  SWZ: 'Eswatini',
  ETH: 'Ethiopia',
  FLK: 'Falkland Islands (Malvinas)',
  FRO: 'Faroe Islands',
  FJI: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  GUF: 'French Guiana',
  PYF: 'French Polynesia',
  ATF: 'French Southern Territories',
  GAB: 'Gabon',
  GMB: 'Gambia',
  GEO: 'Georgia',
  DEU: 'Germany',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRC: 'Greece',
  GRL: 'Greenland',
  GRD: 'Grenada',
  GLP: 'Guadeloupe',
  GUM: 'Guam',
  GTM: 'Guatemala',
  GGY: 'Guernsey',
  GIN: 'Guinea',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HTI: 'Haiti',
  HMD: 'Heard Island and McDonald Islands',
  VAT: 'Holy See',
  HND: 'Honduras',
  HKG: 'Hong Kong',
  HUN: 'Hungary',
  ISL: 'Iceland',
  IND: 'India',
  IDN: 'Indonesia',
  IRN: 'Iran (Islamic Republic of)',
  IRQ: 'Iraq',
  IRL: 'Ireland',
  IMN: 'Isle of Man',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JPN: 'Japan',
  JEY: 'Jersey',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KIR: 'Kiribati',
  PRK: "Korea (the Democratic People's Republic of)",
  KOR: 'Korea (the Republic of)',
  KWT: 'Kuwait',
  KGZ: 'Kyrgyzstan',
  LAO: "Lao People's Democratic Republic",
  LVA: 'Latvia',
  LBN: 'Lebanon',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libya',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MAC: 'Macao',
  MKD: 'Macedonia (the former Yugoslav Republic of)',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malta',
  MHL: 'Marshall Islands',
  MTQ: 'Martinique',
  MRT: 'Mauritania',
  MUS: 'Mauritius',
  MYT: 'Mayotte',
  MEX: 'Mexico',
  FSM: 'Micronesia (Federated States of)',
  MDA: 'Moldova (Republic of)',
  MCO: 'Monaco',
  MNG: 'Mongolia',
  MNE: 'Montenegro',
  MSR: 'Montserrat',
  MAR: 'Morocco',
  MOZ: 'Mozambique',
  MMR: 'Myanmar',
  NAM: 'Namibia',
  NRU: 'Nauru',
  NPL: 'Nepal',
  NLD: 'Netherlands',
  NCL: 'New Caledonia',
  NZL: 'New Zealand',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NIU: 'Niue',
  NFK: 'Norfolk Island',
  MNP: 'Northern Mariana Islands',
  NOR: 'Norway',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PLW: 'Palau',
  PSE: 'Palestine, State of',
  PAN: 'Panama',
  PNG: 'Papua New Guinea',
  PRY: 'Paraguay',
  PER: 'Peru',
  PHL: 'Philippines',
  PCN: 'Pitcairn',
  POL: 'Poland',
  PRT: 'Portugal',
  PRI: 'Puerto Rico',
  QAT: 'Qatar',
  REU: 'R\u00e9union',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  BLM: 'Saint Barth\u00e9lemy',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha',
  KNA: 'Saint Kitts and Nevis',
  LCA: 'Saint Lucia',
  MAF: 'Saint Martin (French part)',
  SPM: 'Saint Pierre and Miquelon',
  VCT: 'Saint Vincent and the Grenadines',
  WSM: 'Samoa',
  SMR: 'San Marino',
  STP: 'Sao Tome and Principe',
  SAU: 'Saudi Arabia',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapore',
  SXM: 'Sint Maarten (Dutch part)',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SLB: 'Solomon Islands',
  SOM: 'Somalia',
  ZAF: 'South Africa',
  SGS: 'South Georgia and the South Sandwich Islands',
  SSD: 'South Sudan',
  ESP: 'Spain',
  LKA: 'Sri Lanka',
  SDN: 'Sudan',
  SUR: 'Suriname',
  SJM: 'Svalbard and Jan Mayen',
  SWE: 'Sweden',
  CHE: 'Switzerland',
  SYR: 'Syrian Arab Republic',
  TWN: 'Taiwan (Province of China)',
  TJK: 'Tajikistan',
  TZA: 'Tanzania, United Republic of',
  THA: 'Thailand',
  TLS: 'Timor-Leste',
  TGO: 'Togo',
  TKL: 'Tokelau',
  TON: 'Tonga',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TKM: 'Turkmenistan',
  TCA: 'Turks and Caicos Islands',
  TUV: 'Tuvalu',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  ARE: 'United Arab Emirates',
  GBR: 'United Kingdom of Great Britain and Northern Ireland',
  UMI: 'United States Minor Outlying Islands',
  USA: 'United States of America',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VUT: 'Vanuatu',
  VEN: 'Venezuela (Bolivarian Republic of)',
  VNM: 'Vietnam',
  VGB: 'Virgin Islands (British)',
  VIR: 'Virgin Islands (U.S.)',
  WLF: 'Wallis and Futuna',
  ESH: 'Western Sahara',
  YEM: 'Yemen',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe'
};

export const THREAT_PROFILE_DOMAINS = {
  ENTERPRISE: 'enterprise-attack',
  ICS: 'ics-attack',
  MOBILE: 'mobile-attack'
};
