import GlobalFiltersButton from 'App/GlobalFilters/GlobalFiltersButton';
import AttackNavigatorButton from './AttackNavigatorButton';
import { gql, useQuery } from '@apollo/client';
import { useGlobalFiltersContext } from 'App/GlobalFilters/GlobalFiltersContext';
import Tooltip from '@mui/material/Tooltip';
import { FilterTechniques } from 'App/Techniques/FilterTechniques';
import TechniquesActiveFilters from 'App/Techniques/TechniquesActiveFilters';
import { ORDER_BY_FIELDS } from 'App/Techniques/utils';
import { PrioritizedTechniqueTypeConnection } from 'core/graphql/graphql';
import { captureEvent } from 'core/utils/capture';
import { useFilterPager } from 'core/utils/useFilterPager';
import { Divider } from 'reablocks';
import { Loader } from 'shared/elements/Loader';
import { SearchInput } from 'shared/form/SearchInput';
import { extractNodes } from 'shared/utils/GraphUtil';
import AttackMatrix from './AttackMatrix';
import css from './AttackMatrix.module.css';

const RAW_TECHNIQUES_QUERY = `
query TechniquesQuery(
  $pageSize: Int!
  $offset: Int! = 0
  $domainName: String!
  $orderBy: String!
  $searchNameOrMitreId: String! = ""
  $tacticNames: String! = ""
  $integrationNames: String! = ""
  $platformNames: String! = ""
  $priority: String! = ""
  $content: String! = ""
  $mitreIds: String! = ""
  $campaignStixIds: String! = ""
  $softwareMitreIds: String! = ""
  $threatGroupMitreIds: String! = ""
  $telemetrySubcategoryNames: String! = ""
  $controlStixIds: String! = ""
  $telemetrySubcategoryId: ID! = ""
  $countries: [ThreatProfileCountries]! = []
  $industries: [ThreatProfileIndustries]! = []
) {
  platformNames
  tacticNames
  integrationNames
  allPrioritizedTechniques(
    first: $pageSize
    offset: $offset
    domainName: $domainName
    orderBy: $orderBy
    search: $searchNameOrMitreId
    tacticNames: $tacticNames
    integrationNames: $integrationNames
    platformNames: $platformNames
    priority: $priority
    content: $content
    mitreIds: $mitreIds
    campaignStixIds: $campaignStixIds
    softwareMitreIds: $softwareMitreIds
    threatGroupMitreIds: $threatGroupMitreIds
    telemetrySubcategoryNames: $telemetrySubcategoryNames
    controlStixIds: $controlStixIds
    telemetrySubcategoryId: $telemetrySubcategoryId
    countries: $countries
    industries: $industries
  ) {
    totalCount
    edges {
      node {
        # Used for keys
        id

        # Displays in each technique box
        name

        # Used for priority icon in each technique
        priority

        # One of these three sets each technique's left border color
        content
        detectionsPercentage
        telemetryPercentage

        # Used in the URL for each technique's link
        mitreId

        # Used to sort the techniques into groups
        tacticNames
      }
    }
  }
}
`;
const TECHNIQUES_QUERY = gql(RAW_TECHNIQUES_QUERY);

const ContentHeader = ({ bgClass, label, range }) => (
  <div className="max-w-[320px] font-main text-md">
    <div className="font-main text-md text-purple100 ">
      What is {label} Coverage?
    </div>
    <Divider className="!my-[12px] flex bg-gray500" />
    <div className="flex items-start gap-sm">
      <div className={'mt-[4px] h-md w-md ' + bgClass} />
      <div>
        {label} indicates {range} coverage of a technique.
      </div>
    </div>
  </div>
);

const MatrixTooltip = ({ bgClass, children, label, range }) => (
  <Tooltip
    componentsProps={{
      popper: {
        className:
          '[&>div]:p-[12px] [&>div]:!m-0 !mt-[16px] ' +
          '[&>div]:overflow-hidden [&>div]:bg-gray600 ' +
          '[&>div]:border [&>div]:border-solid ' +
          '[&>div]:border-purple500 [&>div]:rounded-2xl '
      }
    }}
    placement="bottom"
    title={<ContentHeader {...{ bgClass, label, range }} />}
  >
    {children}
  </Tooltip>
);

/** Renders the legend explaining the colors in the page's upper-right area */
const CoverageLegend = () => (
  <div className={css.coverageLegend}>
    <h2>Coverage</h2>
    {[
      [`Full`, '66+%', 'bg-purple500'],
      [`Moderate`, '34-65%', 'bg-blue500'],
      [`Poor`, '0-33%', 'bg-gray300']
    ].map(([label, range, bgClass]) => (
      <MatrixTooltip key={label} {...{ bgClass, label, range }}>
        <div className={css.color + ' cursor-pointer'}>
          <div className={`h-[15px] w-[27px] ` + bgClass} />
          <span className={css.colorText}>{label}</span>
        </div>
      </MatrixTooltip>
    ))}
  </div>
);

interface TechniquesQueryResult {
  tacticNames: string[];
  integrationNames: string[];
  platformNames: string[];
  allPrioritizedTechniques: PrioritizedTechniqueTypeConnection;
}

const AttackMatrixContainer = () => {
  const props = useFilterPager({
    defaultSort: { field: ORDER_BY_FIELDS.PRIORITY, direction: 'desc' }
  });
  const { variables: globalVariables } = useGlobalFiltersContext();

  const variables = {
    pageSize: props?.pageSize,
    domainName: 'enterprise-attack',
    offset: props.page * props?.pageSize || 0,
    orderBy: `${props.sort.direction === 'asc' ? '' : '-'}${props.sort.field}`,
    searchNameOrMitreId: props.keyword,
    tacticNames: props.filter?.tacticNames?.toLocaleString(),
    integrationNames: props.filter?.integrationNames?.toLocaleString(),
    platformNames: props.filter?.platformNames,
    priority: props.filter?.priority,
    content: props.filter?.coverage,
    mitreIds: props.filter?.techniqueMitreIds,
    campaignStixIds: props.filter?.campaignStixIds,
    softwareMitreIds: props.filter?.softwareMitreIds,
    threatGroupMitreIds: props.filter?.threatGroupMitreIds,
    telemetrySubcategoryNames: props.filter?.telemetrySubcategoryNames,
    controlStixIds: props.filter?.controlStixIds,
    telemetrySubcategoryId: props.filter?.telemetrySubcategoryId,
    // selectedCoverage only impacts the client, not the query
    ...globalVariables
  };
  const { loading, data } = useQuery<TechniquesQueryResult>(TECHNIQUES_QUERY, {
    variables
  });

  if (loading) {
    return <Loader />;
  }

  const {
    integrationNames,
    platformNames,
    allPrioritizedTechniques,
    tacticNames
  } = data;

  const techniques = extractNodes(allPrioritizedTechniques);
  const { filter, savedFilterId, setFilter, setSavedFilterId } = props;
  const { selectedCoverage } = filter ?? { selectedCoverage: 'content' };

  return (
    <div className={css.attackMatrix}>
      <GlobalFiltersButton />
      <div className={css.titleBar}>
        <h1>MITRE ATT&CK® Matrix</h1>
        <div className={css.coverageDetails}>
          <CoverageLegend />
        </div>
        <div className={css.searchAndFilter}>
          <SearchInput
            value={props.keyword}
            placeholder="Search by Name or ID"
            onValueChange={keyword => {
              props.setKeyword(keyword);
              captureEvent('search:techniques', { keyword });
            }}
          />
          <FilterTechniques
            allPlatformNames={platformNames}
            allTacticNames={tacticNames}
            allIntegrationNames={integrationNames}
            filters={filter}
            hasSelectedCoverage={true}
            onFilterChange={setFilter}
            savedFilterId={savedFilterId}
            setSavedFilterId={setSavedFilterId}
          />
          <AttackNavigatorButton
            techniques={techniques}
            selectedCoverage={selectedCoverage}
          />
        </div>
      </div>

      <div className={css.pageHeader}>
        <div></div>
      </div>

      <TechniquesActiveFilters
        {...{ className: css.activeFilters, filter, setFilter }}
      />
      <AttackMatrix {...{ selectedCoverage, techniques }} />
    </div>
  );
};

export default AttackMatrixContainer;
